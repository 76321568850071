import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import YoutubeIcon from '../../../../components/icons/YoutubeIcon';

import Link from '../../../../components/Link';
import Seo from '../../../../components/Seo';

const BurlatskiPisniPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      songs: allSong(filter: {category: {eq: "burlatski-pisni"}}, sort: {fields: title}) {
        nodes {
          title
          slug
          hasYoutube
        }
      }
    }
  `);

  const songs = data.songs.nodes;

  return (
    <>
      <Seo
        title="Бурлацькі пісні"
        description="Бурлацькі пісні — тематична група станових (суспільно-побутових) пісень, мотиви яких відбивають умови життя і праці бурлаків — людей-заробітчан без постійної роботи і постійного місця проживання."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Бурлацькі пісні
          </h1>
          <p className="typo-body mt-4 max-w-2xl">
            Бурлацькі пісні — тематична група станових (суспільно-побутових) пісень, мотиви яких відбивають умови життя
            і праці бурлаків — людей-заробітчан без постійної роботи і постійного місця проживання.
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Список бурлацьких пісень
        </h2>
        <ul className="row mt-8">
          {songs.map((song) => (
            <li className="col-full md:col-4 mb-4">
              <Link to={song.slug} className="typo-small flex items-center hover:text-red-500">
                {song.title}
                {song.hasYoutube && (
                  <YoutubeIcon className="ml-2 w-4 h-4" />
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру бурлацької пісні
        </h2>
        <p className="typo-body mt-4">
          Бурлаки — це селяни, які, втікаючи від закріпачення, ставали на тимчасові заробітки. Вони терпіли поневіряння,
          голод і тяжку працю, зате належали самі до себе; манила воля, але ніде безрідний бурлака її не знаходив: «Степ
          веселий, край далекий, та ніде прожити».
        </p>
        <p className="typo-body mt-4">
          Образність бурлацьких пісень скупіша, ніж в родинно-побутовій ліриці, більше суворого реалізму, нарікань на
          соціальну несправедливість, але в ряді пісень простежується тісний зв’язок з лірикою інших циклів, особливо з
          козацькими піснями.
        </p>
        <p className="typo-body mt-4">
          Настрій туги за рідним краєм, сім’єю, образ смерті на чужині передається за допомогою художніх засобів,
          широко використовуваних в народній ліриці: схилена вишнева гілка — дружина, що втратила надію та повернення
          бурлака; рання роса, що висихає на вітрі — бурлацьке життя, що минає в горюванні; гадина, що в’ється біля
          серця; явір, коріння якого вода підмиває — туга, що точить бурлака.
        </p>
      </div>
    </>
  );
};

export default BurlatskiPisniPage;
